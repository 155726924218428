import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const StyledButton = styled.button`
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;
  display: inline-block;
  padding: ${props => (props.big ? '0 1rem' : '0 0.75rem')};
  font-weight: 600;
  text-decoration: none;
  outline: 0;
  text-align: center;
  box-sizing: border-box;
  background: ${props =>
    props.colorscheme === 'green'
      ? 'linear-gradient(180deg, #77CA34 0%, #71BF32 100%)'
      : props.colorscheme === 'white'
      ? '#FFFFFF'
      : 'linear-gradient(180deg, #16A5F3 0%, #0D9EED 100%)'};
  color: ${props => (props.colorscheme === 'white' ? '#31383C' : '#FFFFFF')};
  border: 1px solid rgba(0, 0, 0, 0.16);
  text-shadow: ${props =>
    props.colorscheme !== 'white'
      ? '0px 1px 0.5px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.05)'
      : 'none'};
  box-shadow: 0px 6px 100px rgba(0, 0, 0, 0.07),
    0px 2.34px 22.3363px rgba(0, 0, 0, 0.0417275),
    0px 2.4px 6.6501px rgba(0, 0, 0, 0.0282725);
  border-radius: ${props => (props.big ? '10px' : '8px')};
  height: ${props => (props.big ? '44px' : '36px')};
  line-height: ${props => (props.big ? '42px' : '34px')};
  font-size: ${props => (props.big ? '1rem' : '0.85rem')};
  width: ${props => (props.stretched ? '100%' : 'auto')};
  transition: all ease-in-out 0.1s;

  svg {
    vertical-align: middle;
  }

  &:hover {
    box-shadow: 0px 9px 100px rgba(0, 0, 0, 0.08),
      0px 5px 22.3363px rgba(0, 0, 0, 0.05),
      0px 5px 6.6501px rgba(0, 0, 0, 0.03);
  }

  &:disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  .spinner {
    animation: rotate 2s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -16px 0 0 -16px;
    width: 32px;
    height: 32px;

    & .path {
      stroke: #fff;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`

const Button = props => {
  let showSpinner

  if (props.showprogress) {
    showSpinner = true
  } else {
    showSpinner = false
  }

  const animProps = {
    scale: props.stretched ? 1.02 : props.preventAnimation ? 1 : 1.03
  }

  return (
    <motion.div
      whileHover={animProps}
      whileTap={{ scale: 1 }}
      style={{ display: props.stretched ? 'block' : 'inline-block' }}>
      <StyledButton {...props}>
        {showSpinner && (
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="5"></circle>
          </svg>
        )}

        {!showSpinner && props.children}
      </StyledButton>
    </motion.div>
  )
}

export default Button
